
import { setPageHeader } from "@/core/helpers/toolbar";
import { defineComponent, onMounted } from "vue";
import Sites from "../components/Sites.vue";
import IpFiltering from "../components/IpFiltering.vue";
import SystemConfig from "../components/SystemConfiguration.vue";
import Taxes from "../components/Taxes.vue";
import Languages from "../components/Languages.vue";
import ShippingMethods from "../components/ShippingMethods.vue";
import PaymentMethods from "../components/PaymentMethods.vue";
import Discounts from "../components/Discounts.vue";
import HomeController from "../components/HomeController.vue";
import SystemSettingsStatus from "../components/SystemSettingsStatus.vue";
import ShopFooter from "../components/ShopFooter.vue";

export default defineComponent({
	name: "system-settings",
	components: {
		SystemConfig,
		Sites,
		Taxes,
		Languages,
		ShippingMethods,
		PaymentMethods,
		Discounts,
		HomeController,
		SystemSettingsStatus,
		ShopFooter,
		IpFiltering,
	},
	setup() {
		onMounted(() => {
			setPageHeader({
				title: "message.SYSTEM message.SETTINGS",
				actionButton: null,
				breadCrumbs: [{ name: "message.HOME", link: "/dashboard" }, { name: "message.SYSTEM", link: "#" }, { name: "message.SETTINGS" }],
			});
		});
	},
});
